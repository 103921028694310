// spacer

$spacer:									1rem !default; // 16px

// margin

$margin-x:									1rem !default;
$margin-y:									1rem !default;

$margin-x-sm:								.5rem !default;
$margin-y-sm:								.5rem !default;

$margin-x-lg:								1.5rem !default;
$margin-y-lg:								1.5rem !default;

$margin-x-xl:								2rem !default;
$margin-y-xl:								2rem !default;

// padding

$padding-x:									1rem !default;
$padding-y:									1rem !default;

$padding-x-sm:								.5rem !default;
$padding-y-sm:								.5rem !default;

$padding-x-lg:								1.5rem !default;
$padding-y-lg:								1.5rem !default;

$padding-x-xl:								2rem !default;
$padding-y-xl:								2rem !default;

// border width

$border-width:								1px !default;

// border radius

$border-radius:								.25rem !default; // 4px
$border-radius-lg:							.3rem !default; // 4.8px
$border-radius-sm:							.2rem !default; // 3.2px
