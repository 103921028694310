$form-font-family:							$font-family-sans-serif !default;
$form-font-size:							$font-size-base !default;
$form-line-height:							$form-font-size * 1.5 !default;
$form-space:								$form-line-height / 2 !default;

$input-label-font-size:						$font-size-base !default;
$input-label-color:							$text-color !default;
$input-label-font-weight:					$font-weight-light !default;

$input-bg:									$white !default;
$input-border:								$grey-lighter !default;
$input-border-focus:						$brand-primary !default;
$input-error:								$brand-danger !default;
$input-shadow:								1px 1px 2px #eee inset !default;
$input-transition:							all .3s ease-in-out !default;

$input-font-size:							$font-size-base !default;
$input-color:								$text-color !default;
$input-font-weight:							$font-weight-light !default;

$form-required:								$brand-danger !default;
$form-muted:								#858585 !default;
