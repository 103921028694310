.nav-mobile {
  // @extend %center-h;
  position: relative;
  margin: .75rem;
  display: block;
  width: 1.5rem;
  height: 1rem;

  @include gridle_state ( md ) {
    display: none;
  }

  &::after {
    position: absolute;
    top: -2px;
    left: 36px;
    font-size: .875rem;
    content: 'MENU';
  }
}

#nav-toggle {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 1.5rem 0 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  span,
  span:before,
  span:after {
    position: absolute;
    display: block;
    height: 2px;
    width: 1.5rem;
    cursor: pointer;
    background: $black;
    border-radius: 1px;
    content: '';
    transition: all 300ms ease-in-out;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  span:before {
    top: 6px;
  }

  span:after {
    bottom: 6px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
