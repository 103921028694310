// layout

.L {
	width: 100%;
	background-color: $white;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.di {
  display: inline;
}

.dn {
  display: none;
}

.B-t {
	border-top: .5rem solid $brand-tertiary;
}

.B-t-sm {
	border-top: .25rem solid $brand-tertiary;
}

.B-t-xs {
	border-top: .125rem solid $brand-tertiary;
}

.B-b {
	border-bottom: .5rem solid $brand-tertiary;
}

.B-b-sm {
	border-bottom: .25rem solid $brand-tertiary;
}

.B-b-xs {
	border-bottom: .125rem solid $brand-tertiary;
}
