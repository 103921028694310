// http://gridle.org/
// bower install -—save gridle

// setting up the grid
@include gridle_setup ( (
	context : $grid-columns,
	gutter-width : $grid-gutter-width,
	direction : ltr,
) );

// iPhone 3 320x480 @1x
// iPhone 4 320x480 @2x
// iPhone 5 320x568 @2x
// iPhone 6 375x667 @3x
// iPhone 6+ 414x736 @3x

// register states
@include gridle_register_state( xs, (
	max-width : 320px
) );
@include gridle_register_state( sm, (
	min-width : 480px
) );
@include gridle_register_state( mb, (
	min-width : 667px
) );
@include gridle_register_state( tb, (
	min-width : 768px
) );
@include gridle_register_state( md, (
	min-width : 1000px
) );
@include gridle_register_state( lg, (
	min-width : 1280px
) );
@include gridle_register_state( xlg, (
	min-width : 1440px
) );

// container sizes
[class="container"] {
	@include gridle_state( xs ) {
		width:100%;
	}
	@include gridle_state( sm ) {
		width:100%;
	}
	@include gridle_state( mb ) {
		width:100%;
	}
	@include gridle_state( tb ) {
		width:750px;
	}
	@include gridle_state( md ) {
		width:960px;
	}
	@include gridle_state( lg ) {
		width:1140px;
	}
	@include gridle_state( xlg ) {
		width:1140px;
	}
}

// mobile first approach
[class*="gr-"] {
	@include gridle_state( xs ) {
		@include gridle_grid( 24 );
	}
}

@include gridle_init();


// generate classes
@include gridle_generate_classes ();

.container {
    @include gridle_container();
    max-width: $grid-max-width;
    margin: 0 auto;
    padding: 0 1rem;
}

// universal mixin
@include gridle(container);
