main {
  article {
    padding-right: 0;

    @include gridle_state ( tb ) {
      padding-right: 10px;
    }

    &:first-child {
      border-top: none;
    }

    p{

      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }

      &:last-child {margin-bottom: 0;}
    }
  }
}
