article {

  ul {
    position: relative;
    margin: 24px 0;

    li {
      position: relative;
      margin-bottom: 8px;
      list-style: none;
      line-height: 24px;

      &:before {
        content: '\2022';
        position: absolute;
        top: 0;
        left: -15px;
        color: $brand-tertiary;
        font-weight: 700;
      }
    }
  }
}

aside,
footer {

  ul.benefits {
    position: relative;
    padding: 0;
    margin: 24px 0;

    li {
      position: relative;
      padding-left: 1rem;
      margin-bottom: 8px;
      list-style: none;
      line-height: 1.25rem;

      &:before {
        content: '+';
        position: absolute;
        top: 0;
        left: 0px;
        color: $brand-primary;
        font-weight: 700;
      }

      &::last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

footer {

  ul.benefits {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
