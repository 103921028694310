// colors

$black:										#000 !default;
$grey-dark:								#595958 !default;
$grey:										#b0afad !default;
$grey-light:							#cecdcc !default;
$grey-lighter:						#eceeef !default;
$grey-lightest:						#e9e8e6 !default;
$white:										#fff !default;

$brand-primary:								#080808 !default;
$brand-secondary:							#F5F4F2 !default;
$brand-tertiary:							rgba(201,226,212,1) !default;

$brand-success:								#5cb85c !default;
$brand-info:								  #5bc0de !default;
$brand-warning:								#f0ad4e !default;
$brand-danger:								#d9534f !default;


.brand-primary { color: $brand-primary; }
.brand-secondary { color: $brand-secondary; }
.brand-tertiary { color: $brand-tertiary; }

.bg-brand-primary { background-color: $brand-primary; }
.bg-brand-secondary { background-color: $brand-secondary; }
.bg-brand-tertiary { background-color: $brand-tertiary; }
