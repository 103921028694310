body#tinymce {
  margin: 12px !important;
  height:auto !important;
}

body.mceContentBody {
  height:auto !important;
  background-color:#FFFFFF !important;
  background-image: none;
  text-align: left;

  h1, h2, h3, h4, h5, h6, p {
  	font-family: $font-family-base;
  }

  h1, .h1 {
    font-size: $font-size-h1;
  }
  h2, .h2 {
    font-size: $font-size-h2;
  }
  h3, .h3 {
    font-size: $font-size-h3;
  }
  h4, .h4 {
    font-size: $font-size-h4;
  }
  h5, .h5 {
    font-size: $font-size-h5;
  }
  h6, .h6 {
    font-size: $font-size-h6;
  }
  p {
    font-size: $font-size-base;
  }
}
