// navigation

$nav-font-family:							inherit !default;
$nav-font-size:								$font-size-xs !default;
$nav-font-color:							$brand-primary !default;
$nav-font-hover-color:				$brand-primary !default;
$nav-font-weight:							$font-weight-bold !default;
$nav-bg-color:								$brand-secondary !default;
$nav-bg-hover-color:					darken($brand-secondary, 7%) !default;

$nav-dd-font-size:						$font-size-xs !default;
$nav-dd-font-color:						$body-text-color !default;
$nav-dd-font-hover-color:			$brand-primary !default;
$nav-dd-bg-color:							$brand-secondary !default;
$nav-dd-bg-hover-color:				$white !default;
$nav-dd-border-bottom:				$border-width solid $grey-lighter;
