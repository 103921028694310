aside {
  font-family: $font-family-base;

  blockquote p {
    font-size: $font-size-base !important;
  }

  .widget {
    padding-left: 0;

    @include gridle_state ( tb ) {
      padding-left: 2rem;
    }

    ul {
      @extend .M-0;
      @extend .P-0;
      list-style: none;

      li {
        @extend .P-b-sm;
        @extend .Fs-sm;

        &:last-child {
          @extend .P-b;
        }
      }
    }

    h3,
    h4 {
      @extend .M-0;
      @extend .P-t-xl;
      @extend .P-b-lg;
      @extend .Fs-sm;
      @extend .Tt-u;
      @extend .Tls;
      position: relative;

      &::after {
        position: relative;
        bottom: -10px;
        display: block;
        height: 4px;
        background-color: $brand-tertiary;
        content: '';
      }
    }

    .popular-posts {

      li {
        position: relative;
        text-align: center;
      }

      h4 {
        position: absolute;
        left: 0;
        bottom: 2rem;
        z-index: 1;
        width: 100%;
        padding: 1.5rem 0;
        background-color: rgba(255, 255, 255, .75);

        &::after {
          display: none;
        }
      }

      img {
        z-index: 0;
      }
    }
  }

  .search-form {
    @extend .P-lr;
    input {width: 100%;}

    .search-submit {
      @extend .btn;
      @extend .btn--btn-primary;
      margin-top: .5rem;
      font-size: $font-size-sm;
    }
  }
}

.single .widget .signup {
  display: none;

  @include gridle_state ( tb ) {
    display: block;
  }
}

.blog .sharing {
  display: none;
}

.widget .sbi_header_text {
  h3 {

    &::after {
      height: 0;
    }
  }
}
