.signup-monogram {
  display: block;
  margin: 2rem auto 1rem;
}

.signup {
  padding: 1rem;
  background: $brand-tertiary;
  outline:1px #fff solid;
  outline-offset: -.5rem;
}

.signup h3,
.widget .signup h3  {
  padding: .5rem 0 0;
  font-size: 1.375rem;
  text-align: center;
  text-transform: none;
  letter-spacing: 0;
}

// .signup h3 {

//   @include gridle_state ( tb ) {
//     font-size: 2rem;
//   }
// }


.signup hr {
  border-color: white;
}

.signup li {
  font-size: .875rem;
}

#mc_embed_signup form {
  padding: 10px !important;
}

#mc_embed_signup {
  input.email {
    width: 100% !important;
  }
}

#mc-embedded-subscribe {
  width: 100%;
  background-color: darken($brand-tertiary, 10%);

  &:hover {
    background-color: darken($brand-tertiary, 20%) !important;
  }
}
