// Typography

$font-family-sans-serif:      'Muli', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-serif:           Georgia, 'Times New Roman', Times, serif !default;
$font-family-alt:             'Sacramento', Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace:       Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$text-color:                  $body-text-color !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-size-root:              16px !default;
$font-size-base:              1rem !default;
$font-size-xl:                1.5rem !default; // 24px
$font-size-lg:                1.25rem !default; // 20px
$font-size-sm:                .875rem !default; // 14px
$font-size-xs:                .63rem !default; // 10.08px

$line-height-base:            1.5 !default;
$line-height-lg:              (4 / 3) !default;
$line-height-sm:              1.5 !default;

$font-size-h1:                2.5rem !default; // 40px
$font-size-h2:                2rem !default; // 32px
$font-size-h3:                1.75rem !default; // 28px
$font-size-h4:                1.5rem !default; // 24px
$font-size-h5:                1.25rem !default; // 20px
$font-size-h6:                1rem !default; // 16px

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        'Julius Sans One', 'Muli', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$headings-font-weight:         $font-weight-normal !default;
$headings-line-height:         1.1 !default;
$headings-font-color:          $brand-primary !default;

$link-color:                   $brand-primary !default;
$link-decoration:              none !default;
$link-hover-color:             darken($link-color, 15%) !default;
$link-hover-decoration:        underline !default;

$hr-border-color:              $grey-lighter !default;
$hr-border-width:              $border-width !default;
