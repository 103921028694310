nav {
  position: relative;
}

.nav-primary,
.nav-footer {
  // @extend %center-h;
  z-index: 2;
  display: none;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  font-size: 0;
  list-style: none;

  li {
    display: block;
  }

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 1rem;
    font-family: $nav-font-family;
    font-size: $nav-font-size;
    font-weight: $font-weight-bold;
    color: $nav-font-color;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-indent: 2px;
    background-color: $nav-bg-color;
    transition: background-color .25s ease;

    &:hover,
    &:focus,
    &:active {
      color: $nav-font-hover-color;
      background-color: $nav-bg-hover-color;
      outline: 0 dotted currentColor;
    }

    // dropdown arrow
    &:not(:only-child):after {
      padding-left: 4px;
      content: ' ▾';
    }
  }

  li.active a {
    color: $nav-font-hover-color;
    background-color: $nav-bg-hover-color;
    outline: 0 dotted currentColor;
  }

  // Dropdown list binds to JS toggle event
  .sub-menu {
    position: static;
    z-index: 3;
    display: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {

    }

    a {
      line-height: 2;
      color: $nav-dd-font-color;
      background-color: $nav-dd-bg-color;

      &:hover,
      &:focus,
      &:active {
        color: $nav-dd-font-hover-color;
        background-color: $nav-dd-bg-hover-color;
      }
    }
  }

  .active {
    a {
      background-color: $brand-secondary;
    }
  }
}

@include gridle_state ( md ) {

  .nav-primary {
    display: inline-block !important;
    width: auto;

    li {
      display: inline-block;
    }

    a,
    a:visited {
      padding: 1rem;
      line-height: 1;
    }

    // Dropdown list binds to JS toggle event
    .sub-menu {
      position: absolute;
      width: auto;
      min-width: 200px;

      li {
        display: block;
      }
    }
  }
}

.nav-footer {
  display: block;

  li {
    display: inline-block;
  }
}

