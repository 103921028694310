// Typography

// @import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
// @import url(https://fonts.googleapis.com/css?family=Muli:400,700);
// @import url(https://fonts.googleapis.com/css?family=Sacramento);
// @import url('https://fonts.googleapis.com/css?family=Julius+Sans+One');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Muli:400,700|Sacramento');
// @import url('https://fonts.googleapis.com/css?family=Julius+Sans+One|Muli:400,700|Sacramento');

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  // margin-bottom: $headings-margin-bottom;
  padding: 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-font-color;
}

h1, .h1 {
  font-size: $font-size-h1;
}
h2, .h2 {
  font-size: $font-size-h2;
}
h3, .h3 {
  font-size: $font-size-h3;
}
h4, .h4 {
  font-size: $font-size-h4;
}
h5, .h5 {
  font-size: $font-size-h5;
}
h6, .h6 {
  font-size: $font-size-h6;
}

p {
  margin: 0 0 $font-size-base;
  font-family: inherit;
  font-size: $font-size-base;
  color: $text-color;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
}

article p {
  text-align: justify;
}

small, .small {
  font-size: $font-size-sm;
}

span {
  color: $brand-primary;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

blockquote {
  position: relative;
  padding: 2rem;
  margin: 2rem 0;
  border-left: .25rem solid $brand-tertiary;

  p {
    margin-bottom: 0;
    // font-family: $font-family-alt;
    // font-style: italic;
    // font-size: 1rem;
  }
}

// Anchor

.Ad-n {text-decoration: none;
  &:hover {text-decoration: none;}
}

// Text Align

.Ta-l {text-align: left;}
.Ta-r {text-align: right;}
.Ta-c {text-align: center;}

// Font Family

.Ff-sans-serif {font-family: $font-family-sans-serif;}
.Ff-serif {font-family: $font-family-serif;}
.Ff-alt {font-family: $font-family-alt;}

// Font Size

.Fs-b {font-size: $font-size-base;} // 16
.Fs-xl {font-size: $font-size-xl;} // 24
.Fs-lg {font-size: $font-size-lg;} // 20
.Fs-sm {font-size: $font-size-sm;} // 14
.Fs-xs {font-size: $font-size-xs;} // 10

.Fs-banner {font-size: 2.75rem} // 44
.Fs-subbanner {font-size: 1.75rem} // 32

// Font Weight

.Fw-l {font-weight: $font-weight-light;}
.Fw-n {font-weight: $font-weight-normal;}
.Fw-b {font-weight: $font-weight-bold;}

// Text Color

.Tc-m {color: $grey;}

// Text Transform

.Tt-l {text-transform: lowercase;}
.Tt-u {text-transform: uppercase;}
.Tt-c {text-transform: capitalize;}

// Letter Spacing

.Tls {letter-spacing: 4px; text-indent: 4px;}
.Tls-c {letter-spacing: 4px; text-indent: 4px;}
