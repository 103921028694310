.tp_recent_tweets {
  float: none;
  clear: none;

  ul {
    @extend .clearfix;
    li {
      display: block;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      width: 100%;
      font-size: $font-size-sm !important;
      background-position: 0 20px;
      @extend .B-t-xs;
    }
  }
}