section.widget {
  position: relative;

  .social-menu {
    position: relative;
    right: auto;
    bottom: auto;
    margin: 0;
  }
}

@include b(social-menu) {
  // @extend %center-h;
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
  font-size: 0;

  @include m(header) {

    @include gridle_state ( tb ) {
      position: absolute;
      right: 1rem;
      bottom: 2.5rem;
    }
  }

  @include m(header) {

  }

  // @include m(append) {
  //   display: inline-block;
  //   padding-top: 3.75rem;
  // }

  @include m(dark) {

    .social-icon {
      .circle {
        fill: none;
        stroke: $text-color;
        stroke-width: 2;
      }
      .icon {
        fill: $text-color;
      }
      &:hover {
        .circle {
          stroke: darken($brand-tertiary, 18%);
        }
        .icon {
          fill: darken($brand-tertiary, 18%);
        }
      }
    }
  }

  @include e(item) {
    display: inline-block;
    margin-right: .75rem;
    padding: 0 !important;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}

// nav .social-menu {
//   position: absolute;
//   top: 1px;
//   right: 0;
//   margin: .5rem;
// }

// nav .social-menu .social-menu__item a {
//   height: 24px;
//   width: 24px;
// }

.sb_instagram_header a.sbi_header_link {
  display: flex;
  align-items: center;
}
.sb_instagram_header .sbi_header_text {
  padding-top: 0;
}

.sb_instagram_header .sbi_header_hashtag_icon {
  background: rgba(201,226,212,1);
  background-color: $brand-tertiary;
  opacity: 1;
}
