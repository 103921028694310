.sharing {
  padding: 1rem;
  text-align: center;
  background: $brand-secondary;
  outline:1px #fff solid;
  outline-offset: -.5rem;
}

.sharing h3  {
  padding: .5rem 0 0;
  font-size: 1.375rem;
  text-align: center;
  text-transform: none;
  letter-spacing: 0;

  // @include gridle_state ( tb ) {
  //   font-size: 2rem;
  // }
}

// .sharing hr {
//   border-color: $brand-primary;;
// }

.jssocials-share-link {
  padding: 0;
}

.jssocials-share-facebook .jssocials-share-link,
.jssocials-share-twitter .jssocials-share-link,
.jssocials-share-facebook .jssocials-share-link:hover,
.jssocials-share-twitter .jssocials-share-link:hover {
  background: transparent;
}
