.sub-nav {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
  list-style-type: none;

  @include gridle_state ( tb ) {
    flex-direction: row;
  }

  li {
    position: relative;
    margin-right: 0;
    margin-bottom: 1rem;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    @include gridle_state ( tb ) {
      flex-direction: row;
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  h4 {
    @extend .M-0;
    @extend .P;
    // @extend .B-t-sm;
    @extend .Fs-sm;
    @extend .Tt-u;
    @extend .Tls;

    position: absolute;
    left: 0;
    bottom: 2rem;
    z-index: 1;
    width: 100%;
    background-color: rgba(255, 255, 255, .75);
  }

  img {
    z-index: 0;
  }
}
