// margin

.M {

  margin: $margin-y $margin-x;

}
.M-sm {

  margin: $margin-y-sm $margin-x-sm;

}
.M-lg {

  margin: $margin-y-lg $margin-x-lg;

}
.M-xl {

  margin: $margin-y-xl $margin-x-xl;

}
.M-0 {

  margin: 0;

}

// margin top bottom

.M-tb {

  margin-top: $margin-y;
  margin-bottom: $margin-y;

}
.M-tb-sm {

  margin-top: $margin-y-sm;
  margin-bottom: $margin-y-sm;

}

.M-tb-lg {

  margin-top: $margin-y-lg;
  margin-bottom: $margin-y-lg;

}

.M-tb-xl {

  margin-top: $margin-y-xl;
  margin-bottom: $margin-y-xl;

}

// margin top

.M-t {

  margin-top: $margin-y;

}
.M-t-sm {

  margin-top: $margin-y-sm;

}

.M-t-lg {

  margin-top: $margin-y-lg;

}

.M-t-xl {

  margin-top: $margin-y-xl;

}

// margin bottom

.M-b {

  margin-bottom: $margin-y;

}
.M-b-sm {

  margin-bottom: $margin-y-sm;

}

.M-b-lg {

  margin-bottom: $margin-y-lg;

}

.M-b-xl {

  margin-bottom: $margin-y-xl;

}

// padding

.P {

  padding: $padding-y $padding-x;

}
.P-sm {

  padding: $padding-y-sm $padding-x-sm;

}
.P-lg {

  padding: $padding-y-lg $padding-x-lg;

}
.P-xl {

  padding: $padding-y-lg $padding-x-lg;

}
.P-0 {

  padding: 0;

}

// padding top bottom

.P-tb {

  padding-top: $padding-y;
  padding-bottom: $padding-y;

}

.P-tb-sm {

  padding-top: $padding-y-sm;
  padding-bottom: $padding-y-sm;

}

.P-tb-lg {

  padding-top: $padding-y-lg;
  padding-bottom: $padding-y-lg;

}

.P-tb-xl {

  padding-top: $padding-y-xl;
  padding-bottom: $padding-y-xl;

}

// padding top

.P-t {

  padding-top: $padding-y;

}
.P-t-sm {

  padding-top: $padding-y-sm;

}

.P-t-lg {

  padding-top: $padding-y-lg;

}

.P-t-xl {

  padding-top: $padding-y-xl;

}

// padding bottom

.P-b {

  padding-bottom: $padding-y;

}
.P-b-sm {

  padding-bottom: $padding-y-sm;

}

.P-b-lg {

  padding-bottom: $padding-y-lg;

}

.P-b-xl {

  padding-bottom: $padding-y-xl;

}

// padding left right

.P-lr {

  padding-left: $padding-x;
  padding-right: $padding-x;

}

.P-l {

  padding-left: $padding-x;

}

.P-r {

  padding-right: $padding-x;

}

