// Utility classes

.first {
    margin-top: 0 !important;
}
.last {
    margin-bottom: 0 !important;
}
.left {
    float: left !important;
}
.right {
    float: right !important;
}

// Image replacement
// https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757c9e03dda4e463fb0d4db5a5f82d7#commitcomment-1052728

.ir { 
    border:0;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    background-color: transparent;
}


// Hide visually and from screen readers/

.hidden {
    display: none !important;
}

// Hide only visually, but have it available for screen readers:
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// Hide visually and from screen readers, but maintain layout

.invisible {
    visibility: hidden;
}

// Clearfix

.clearfix {
    @extend %clearfix;
}
