footer {
  p {
    small {
      font-size: $font-size-xs !important;
    }
    &:last-child {
      margin: 0;
    }
  }
}
